@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter/Inter-VariableFont_slnt,wght.ttf);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter/Inter-ExtraBold-slnt=0.ttf);
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter/Inter-Bold-slnt=0.ttf);
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter/Inter-SemiBold-slnt=0.ttf);
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter/Inter-Medium-slnt=0.ttf);
  font-weight: 500;
  font-style: normal;
}